import { SvgImage } from "components/shared";

type ContactSupportLinkProps = {
  hasMPLogo?: boolean;
};

export const ContactSupportLink = ({ hasMPLogo }: ContactSupportLinkProps) => {
  return (
    <div className="mt-2 text-center">
      {hasMPLogo && (
        <div className="flex flex-row items-center space-x-1 justify-center">
          <p className="text-label font-norma text-sm">powered by</p>
          <SvgImage name="MPLogo" className="mt-1" width={116} height={39} />
        </div>
      )}
      <a
        href="mailto:support@motorplatform.com.au?subject=Customer Inspection Support"
        className="text-secondary text-sm underline"
        target="_blank"
        rel="noreferrer"
      >
        Contact Support
      </a>
    </div>
  );
};
