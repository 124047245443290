import { useMutation } from "@tanstack/react-query";
import { ReactNode, useEffect, useState } from "react";

import { InformativeModal } from "components/Modal/InformativeModal";
import { Modal, SvgImage } from "components/shared";

import { ActionResult, ActionResultMessage } from "utils";

import { CustomerInspectionService } from "api/client";

import { useGeolocation } from "hooks/useGeolocation";

import { GeolocationInstructions } from "./GeolocationInstructions";

type GeolocationProps = {
  children: ReactNode;
  enableChecking: boolean;
  id: string;
};

export const Geolocation = ({
  id,
  enableChecking,
  children,
}: GeolocationProps) => {
  const { state, requestGeolocation } = useGeolocation({
    enableChecking,
  });
  const [showModal, setShowModal] = useState(false);

  const { status, error, latitude, longitude, permission } = state;

  const { mutate } = useMutation(
    (payload: { latitude: string; longitude: string }) =>
      CustomerInspectionService.sendGPSLocation(payload, id)
  );

  const renderStatus = () => {
    if (status === "error") {
      return <p>{error}</p>;
    }

    if (status === "disabled") {
      return <p>Geolocation is disabled in your browser.</p>;
    }

    if (status === "idle" || status === "pending") {
      return <p>Please enable Geolocation</p>;
    }

    return null;
  };

  useEffect(() => {
    if (enableChecking && status === "success") {
      mutate({ latitude: `${latitude}`, longitude: `${longitude}` });
    }
  }, [status, enableChecking, latitude, longitude, mutate]);

  const onCheckStatus = () => {
    requestGeolocation();

    if (permission === "denied" || status === "error") {
      setShowModal(true);
    }
  };

  if (
    ["error", "disabled", "idle", "pending"].includes(status) &&
    enableChecking
  ) {
    return (
      <>
        <div className="flex justify-center items-center h-[100vh] text-academicBlue mb-1 gap-5 flex-col">
          <div className="flex items-center gap-3">
            <SvgImage name="ConfirmIcon" />
            {renderStatus()}
          </div>
          <div>
            <button
              onClick={onCheckStatus}
              className="mt-2 px-4 py-2 bg-academicBlue text-white rounded hover:bg-academicBlueDark"
            >
              {status === "pending"
                ? "Refreshing..."
                : status === "error"
                ? "Refresh"
                : "Check Status"}
            </button>
          </div>
        </div>
        <Modal
          open={showModal}
          size="auto"
          canClose={false}
          closeModal={() => setShowModal(false)}
        >
          <InformativeModal
            content={{
              title:
                ActionResultMessage[ActionResult.DisabledGPSPermission].title,
              message: `${
                ActionResultMessage[ActionResult.DisabledGPSPermission].message
              }`,
            }}
            subContent={<GeolocationInstructions />}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      </>
    );
  }

  return <>{children}</>;
};
