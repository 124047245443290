type RedirectToGoogleMapsProps = {
  location?: string;
};

export const RedirectToGoogleMaps = ({
  location,
}: RedirectToGoogleMapsProps) => {
  if (!location) {
    return null;
  }

  return (
    <div>
      <a
        href={location}
        className="text-secondary"
        target="_blank"
        rel="noreferrer"
      >
        GPS Coordinates* (Hyperlink to Map)
      </a>
    </div>
  );
};
