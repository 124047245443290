import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ModalCompleteInspection } from "components/app";
import { Geolocation } from "components/Geolocation";
import { InformativeModal } from "components/Modal/InformativeModal";
import { Button, ButtonLink, SvgImage, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ActionResult,
  ActionResultMessage,
  INSPECTION_STEP,
  showErrorToast,
  showSuccessToast,
} from "utils";

import { ISection } from "types";

import { CustomerInspectionService, InspectionService } from "api/client";

import { useGeolocation } from "hooks/useGeolocation";

import { UploadScreen } from "./UploadScreen";

export const Pictures = () => {
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const [showUploadScreen, setShowUploadScreen] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState(true);
  const [showBanner, setShowBanner] = useState<boolean>(true);

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  const { data: { data } = {}, refetch } = useQuery(
    [`fetch_inspection_${params.id}_${userID}`, params.id],
    CustomerInspectionService.showInspection,
    { refetchOnWindowFocus: false }
  );

  const { data: { data: sectionData } = {} } = useQuery<
    { data: ISection[] },
    Error
  >(["fetchSection"], InspectionService.getSections, {
    refetchOnWindowFocus: false,
  });

  const isVehicleVerification =
    verifyData?.data?.is_vehicle_verification || false;

  const { state } = useGeolocation({ enableChecking: isVehicleVerification });

  const isAllImagesUploaded = useMemo(() => {
    if (!isVehicleVerification) {
      return true;
    }

    const uploadedImages = data?.images;
    return sectionData?.every(({ label }) =>
      uploadedImages?.some(
        (image) => image?.label?.toLowerCase() === label.toLowerCase()
      )
    );
  }, [isVehicleVerification, data, sectionData]);

  const { mutate, isLoading } = useMutation(
    (payload: any) =>
      CustomerInspectionService.submitInspection(payload, params.id!),
    {
      onSuccess: () => {
        showSuccessToast("Saved Successfully");
        navigate("../complete");
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTakePicture = () => {
    setShowUploadScreen(true);
  };

  const handleCompleteInspection = () => {
    setShowConfirmModal(true);
  };

  const handleBanner = () => {
    setShowBanner(false);
  };

  const handleConfirm = () => {
    const payload = isVehicleVerification
      ? { ...data, latitude: state.latitude, longitude: state.longitude }
      : data;

    mutate(payload);
  };

  return (
    <Geolocation
      enableChecking={isVehicleVerification && showUploadScreen}
      id={params.id || ""}
    >
      <div className="flex flex-col justify-between text-primary w-full h-[calc(100%-50px)]">
        <div className="absolute h-0 md:h-10 w-full left-0 border-b-[7px] border-softPeach">
          <div className="bg-primary h-[7px] mt-0 md:mt-33w-full md:mt-33"></div>
        </div>
        <div className="flex-1 w-full flex flex-col overflow-auto px-5 mt-0 md:mt-[40px]">
          <div className="text-black text-sm pt-7 -mt-[7px] md:mt-0 ">
            <ButtonLink onClick={handleGoBack}>
              {"< Back to Conditions"}
            </ButtonLink>
          </div>
          {showBanner && showUploadScreen && (
            <div className="relative">
              <div className="take-picture__banner">
                Better Pictures = Higher Valuation
                <ButtonLink onClick={handleBanner}>
                  <SvgImage name="PictureCloseIcon" className="ml-2" />
                </ButtonLink>
              </div>
            </div>
          )}
          <h5 className="text-primary font-bold text-23 mt-4 mb-0">Pictures</h5>
          <div className="take-picture__step">
            <label className="text-primary text-sm font-bold leading-10">
              STEP 3/3 - VEHICLE PHOTOS
            </label>
            {!showUploadScreen && (
              <div className="mt-4">
                <SvgImage name="TakePictureIcon" />
              </div>
            )}
          </div>
          {!showUploadScreen ? (
            <div className="relative flex flex-col my-5">
              <p className="text-lg text-lightGrey font-bold mb-5">
                Photograph your vehicle.
              </p>
              <p className="text-txt mb-5 !text-base">
                Follow the titles of the placeholder images and take
                corresponding pictures of your vehicle.
              </p>
              <p className="text-txt !text-base">
                If you are not in front of your car you can always come back to
                this section by clicking on the link you have received from us.
              </p>
            </div>
          ) : (
            <UploadScreen
              data={data}
              inspectionId={params.id!}
              onRefresh={refetch}
              sections={sectionData || []}
              disableUpload={verifyData?.data?.is_vehicle_verification}
              isVehicleVerification={isVehicleVerification}
            />
          )}
        </div>
        <div className="customer__bottom-bar !gap-1">
          {!showUploadScreen ? (
            <Button type="submit" onClick={handleTakePicture}>
              <span className="font-bold">Take Pictures</span>
            </Button>
          ) : (
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={!isAllImagesUploaded}
              onClick={handleCompleteInspection}
            >
              <span className="font-bold">Complete Inspection</span>
            </Button>
          )}
          <div className="flex flex-row items-center space-x-1 justify-center">
            <p className="text-label font-normal text-xs">powered by</p>
            <SvgImage name="MPLogo" className="mt-1" width={116} height={39} />
          </div>
        </div>
        <Modal
          open={
            isVehicleVerification &&
            showWarning &&
            state.permission !== "granted"
          }
          size="auto"
          canClose={false}
          closeModal={() => setShowWarning(false)}
        >
          <InformativeModal
            content={{
              title: ActionResultMessage[ActionResult.WarningToEnableGPS].title,
              message: `${
                ActionResultMessage[ActionResult.WarningToEnableGPS].message
              }`,
            }}
            onClose={() => setShowWarning(false)}
          />
        </Modal>
        <Modal
          size="auto"
          open={showConfirmModal}
          closeModal={() => setShowConfirmModal(false)}
        >
          <ModalCompleteInspection
            onConfirm={handleConfirm}
            closeModal={() => setShowConfirmModal(false)}
          />
        </Modal>
      </div>
    </Geolocation>
  );
};
