import { useQuery } from "@tanstack/react-query";
import {
  MANUAL_SEARCH_STEPS,
  VEHICLE_SEARCH_OPTIONS,
} from "enums/VehicleSearch";
import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormSelectVariant } from "components/app";
import { ISearchFormType } from "components/app/Form/Search/FormManualSearch";
import { Button } from "components/shared";
import { VehicleSearch } from "components/VehicleSearch";
import { ManualSearchFormFields } from "components/VehicleSearch/ManualSearchFormFields";

import { AuthContext } from "context/AuthContext";

import { CUSTOMER_SEARCHED_VEHICLE, INSPECTION_STEP } from "utils";

import { IVariant } from "types";

import { CustomerInspectionService, VehicleSearchService } from "api/client";

import { useLocalStorage } from "hooks";

import "./styles.scss";

type VehicleInfo = {
  year: string;
  make: string;
  model: string;
};

export const ManualSearch = () => {
  const { userID } = useContext(AuthContext);

  const methods = useForm();
  const navigate = useNavigate();
  const params = useParams();

  const [selectedSearchOptions, setSelectedSearchOptions] =
    useState<ISearchFormType | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<IVariant | null>(null);
  const [step, setStep] = useState<MANUAL_SEARCH_STEPS>(
    MANUAL_SEARCH_STEPS.VEHICLE_SEARCH
  );
  const [totalResults, setTotalResults] = useState<number>();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [_, saveVehicle] = useLocalStorage(CUSTOMER_SEARCHED_VEHICLE, null);

  const allFieldsHaveValues = (obj: VehicleInfo): boolean =>
    Object.values(obj).every((value) => Boolean(value));

  const { data: verifyData } = useQuery(
    [`verify_inspection_link_${userID}`, params.id],
    CustomerInspectionService.verifyInspectionLInk
  );

  useEffect(() => {
    if (verifyData) {
      const { status } = verifyData.data;

      if (status.name === INSPECTION_STEP.INSPECTION_SUBMITTED) {
        return navigate(`/inspection/customer/${params.id}/complete`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyData]);

  const handleFindVehicle = async () => {
    try {
      setIsSearching(true);
      const searchResult = await VehicleSearchService.searchNvic(
        selectedVariant?.code || ""
      );

      if (searchResult) {
        setIsSearching(false);
        saveVehicle(searchResult.data);
        navigate("../vehicle-confirmation");
      }
    } catch (err) {
      setIsSearching(false);
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
  };

  const handleNextStep = methods.handleSubmit((data: any) => {
    if (
      allFieldsHaveValues(data) &&
      step === MANUAL_SEARCH_STEPS.VEHICLE_SEARCH
    ) {
      setSelectedSearchOptions(data);
      setStep(MANUAL_SEARCH_STEPS.SELECT_VARIANT);
    }
  });

  const handleClose = () => {
    setStep(MANUAL_SEARCH_STEPS.VEHICLE_SEARCH);
    setSelectedVariant(null);
  };

  if (step === MANUAL_SEARCH_STEPS.VEHICLE_SEARCH) {
    return (
      <FormProvider {...methods}>
        <VehicleSearch
          vehicleSearchOption={VEHICLE_SEARCH_OPTIONS.MANUAL_SEARCH}
          handleSearch={handleNextStep}
        >
          <ManualSearchFormFields />
        </VehicleSearch>
      </FormProvider>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100%-30px)] vehicle-search mt-[-60px] pt-[60px]">
      <div className="flex-shrink px-5">
        <h5 className="font-bold text-2xl pt-8 pb-5">Select variant</h5>
        <div className="total-results flex gap-5 items-center pb-3 text-sm border-b-[#D7DFEA] border-b-solid border-b-1">
          <span className="bg-[#F5F9FF] rounded-[15px] py-1 px-3">
            {totalResults || 0} Results
          </span>
          <p className="margin-0">
            {`For ${selectedSearchOptions?.make} ${selectedSearchOptions?.model} ${selectedSearchOptions?.year}...`}
          </p>
        </div>
      </div>
      <div className="flex-grow-1 h-full overflow-y-auto px-5">
        <FormSelectVariant
          selectedVariant={selectedVariant}
          selectedSearchOptions={selectedSearchOptions}
          onNextStep={(value) => {
            setSelectedVariant(value);
          }}
          totalResults={(total) => setTotalResults(total)}
        />
      </div>
      <div className="flex-shrink px-5">
        <p className="text-sm text-label py-5">
          No exact match? No worries. You can edit andy information in the next
          steps.
        </p>
        <div className="flex gap-3">
          <Button
            variant="secondary"
            type="submit"
            isLoading={false}
            onClick={handleClose}
          >
            <span className="uppercase text-lightGrey text-sm font-bold">
              Go back
            </span>
          </Button>
          <Button
            type="submit"
            isLoading={isSearching}
            onClick={() => handleFindVehicle()}
            disabled={!selectedVariant}
          >
            <span className="uppercase text-white text-sm font-bold">
              Continue
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
