import { SvgImage } from "components/shared";

type WarningProps = {
  iconName?: string;
  title?: string;
  description?: string;
};

export const Warning = ({ iconName, title, description }: WarningProps) => {
  return (
    <div className="flex bg-[#FFF7E5] p-3 items-start rounded-10 gap-2">
      {iconName && <SvgImage name={iconName} />}
      <div>
        {title && <p className="font-bold text-sm">{title}</p>}
        {description && <p className="text-sm">{description}</p>}
      </div>
    </div>
  );
};
