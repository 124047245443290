import { BrowserType } from "enums/DeviceType";
import { useEffect, useState } from "react";

import { useBrowserInfo } from "hooks/useBrowserInfo";

export const GeolocationInstructions = () => {
  const [instructions, setInstructions] = useState<string | null>(null);
  const browser = useBrowserInfo();

  useEffect(() => {
    let browserInstructions;
    switch (browser) {
      case BrowserType.Chrome:
        browserInstructions =
          "Go to Chrome Settings > Privacy and Security > Site Settings > Location.";
        break;
      case BrowserType.Firefox:
        browserInstructions =
          "Go to Firefox Settings > Privacy & Security > Permissions > Location.";
        break;
      case BrowserType.Safari:
        browserInstructions = "Go to Safari Preferences > Websites > Location.";
        break;
      case BrowserType.Edge:
        browserInstructions =
          "Go to Edge Settings > Cookies and site permissions > Location.";
        break;
      default:
        browserInstructions =
          "Check your browser's settings to enable geolocation.";
    }

    setInstructions(browserInstructions);
  }, []);

  return (
    <div className="text-txt">
      <h2 className="font-semibold text-base">How to Enable Geolocation</h2>
      {instructions && (
        <div className="mt-2">
          <p className="text-sm">{instructions}</p>
        </div>
      )}
    </div>
  );
};
