import { BrowserType } from "enums/DeviceType";

export const useBrowserInfo = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (userAgent.includes("edg")) {
    return BrowserType.Edge;
  } else if (
    (userAgent.includes("chrome") &&
      !userAgent.includes("edg") &&
      !userAgent.includes("opr")) ||
    userAgent.includes("crios")
  ) {
    return BrowserType.Chrome;
  } else if (userAgent.includes("firefox")) {
    return BrowserType.Firefox;
  } else if (
    userAgent.includes("safari") &&
    !userAgent.includes("chrome") &&
    !userAgent.includes("edg")
  ) {
    return BrowserType.Safari;
  }

  return BrowserType.Other;
};
