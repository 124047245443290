import { useQuery } from "@tanstack/react-query";
import { ContractType } from "enums/ContractType";
import { useContext, useState } from "react";

import { FieldState, ButtonStatus, ModalEditKM } from "components/app";
import { LinkButton } from "components/Button";
import { SvgImage, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  getCurrentPrice,
  formatNumber,
  FLOW_TYPE,
  convertToDate,
  DECLINED_VALUATION,
  showErrorToast,
  StorageKeys,
  REVIEW,
  DRAFT,
  VERIFICATION_APPROVED,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";

import { ValuationService } from "api/client";

interface SummaryHeaderProps {
  data: IVehicleSummary;
  flow?: FLOW_TYPE;
  onRefresh: () => void;
}

export const SummaryHeader = ({
  data,
  flow = FLOW_TYPE.RETAIL_DEALERSHIP,
  onRefresh,
}: SummaryHeaderProps) => {
  const { userID, hasPermission } = useContext(AuthContext);

  const [openKMModal, setOpenKMModal] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const {
    vehicle,
    status,
    created_by: { name: staffName } = {},
    dealership,
    created_at,
    valuations,
    acquisitions,
    is_locked,
    contract_id,
    owner,
    is_contract_owner,
    seller,
    requester,
    type,
    verification,
  } = data;

  const { name, compliance, odometer, specification } = vehicle || {};

  const { colour } = specification || {};

  const { registration_plate, registration_state, registration_expiry_date } =
    compliance || {};

  const statusName = status?.name;

  const isVerification = type === ContractType.Verification;

  const {
    isFetching: isFetchingValuationCertificate,
    refetch: refetchValuationCertificate,
  } = useQuery<string, Error>(
    [StorageKeys.ValuationCertificate, contract_id, userID],
    async () =>
      await ValuationService.requestValuationCertificate(contract_id || ""),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: string) => {
        window.open(data, "_blank", "noreferrer");
      },
      onError: (err: Error) => {
        console.log("err", err);
        showErrorToast("Failed to Open Certificate");
      },
    }
  );

  const handleEditKM = () => {
    setOpenKMModal(true);
  };

  const closeKMModal = () => {
    setOpenKMModal(false);
    onRefresh();
  };

  const expiryDate =
    typeof registration_expiry_date === "string"
      ? registration_expiry_date
      : registration_expiry_date?.value;

  return (
    <>
      <div className="mb-1">
        <div className="text-2xl text-txt font-semibold">{name}</div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex items-center text-sm text-label gap-3">
            {(registration_plate || registration_state) && (
              <>
                <span>{registration_plate}</span>
                <span>{registration_state}</span>
                <span>|</span>
              </>
            )}
            <span>Km: {formatNumber(odometer) || "-"}</span>
            {!is_locked && (
              <div className="flex items-center ml-2">
                <LinkButton onClick={handleEditKM}>
                  <SvgImage name="EditPen" width={12} />
                </LinkButton>
              </div>
            )}
            <span>|</span>
            <span>Colour: {colour || "-"}</span>
            <span>|</span>
            <span>Rego Expiry: {convertToDate(expiryDate)}</span>
          </div>
          {![REVIEW.key, DRAFT.key].includes(statusName) &&
            (is_contract_owner || seller) &&
            hasPermission(
              PermissionCategory.VALUATION_CERTIFICATE,
              PermissionAction.VIEW_ANY
            ) &&
            (isFetchingValuationCertificate ? (
              <span className="text-label text-sm font-medium">
                {"Opening Valuation Certificate..."}
              </span>
            ) : (
              <LinkButton
                onClick={refetchValuationCertificate}
                disabled={isFetchingValuationCertificate}
              >
                {"View Valuation Certificate PDF >"}
              </LinkButton>
            ))}
        </div>
      </div>
      <div className="rounded-xl w-full h-fit p-6 flex flex-col gap-4 shadow-sm bg-[#f6f7f9]">
        <div className="flex w-full">
          <div className="flex flex-col flex-1 gap-3">
            <FieldState
              name="Status"
              style={{ alignItems: "center" }}
              value={
                <div className="flex">
                  <ButtonStatus
                    status={statusName}
                    amount={getCurrentPrice(data)}
                    label={
                      statusName === DECLINED_VALUATION.key
                        ? DECLINED_VALUATION.label
                        : statusName === VERIFICATION_APPROVED.key
                        ? VERIFICATION_APPROVED.label
                        : status?.label
                    }
                  />
                </div>
              }
            />
            {isVerification ? (
              <>
                <FieldState name="Dealership" value={dealership?.name} />
                <FieldState name="Customer Name" value={owner?.full_name} />
              </>
            ) : (
              <>
                {owner ? (
                  <FieldState name="Customer Name" value={owner.full_name} />
                ) : (
                  <FieldState
                    name="Dealership"
                    value={
                      flow === FLOW_TYPE.WHOLESALE_DEALERSHIP
                        ? requester?.dealership?.name ||
                          seller?.name ||
                          dealership?.name
                        : user?.data.dealership?.name
                    }
                  />
                )}
                <FieldState
                  name="Staff Name"
                  value={
                    flow === FLOW_TYPE.WHOLESALE_DEALERSHIP
                      ? requester?.user?.name || staffName
                      : staffName
                  }
                />
              </>
            )}
          </div>
          <div className="flex flex-col flex-1 gap-3">
            {isVerification ? (
              <>
                <FieldState name="Received" value={verification?.received_at} />
                <FieldState
                  name="Submitted"
                  value={verification?.submitted_at}
                />
              </>
            ) : (
              <>
                <FieldState name="Created" value={created_at} />
                <FieldState
                  name="Offer Sent"
                  value={valuations?.internal?.offer?.created_at}
                />
                <FieldState
                  name="Acquired"
                  value={
                    acquisitions?.created_at ||
                    acquisitions?.acquisition?.created_at
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={openKMModal}
        size="auto"
        title="Edit Kilometres"
        closeModal={() => setOpenKMModal(false)}
      >
        <ModalEditKM
          onCloseModal={closeKMModal}
          contract_id={contract_id}
          odometer={odometer}
        />
      </Modal>
    </>
  );
};
